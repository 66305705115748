$(document).ready(function () {
	// Маска <input name="phone">
	$('[name=phone]').mask('+7 (999) 999-99-99');

	// Отправка формы
	$('.form').on('submit', function(e){
		form = $(this);
		data = form.serialize();
		$.ajax({
			url: './php/main.php?sendLead',
			type: 'POST',
			dataType: 'json',
			data: data,
			success: function() {
				$('.form')[0].reset();
				alert('Success');
			}
		})
		e.preventDefault();
	});
});